import React from "react";
import { Image, Box, Button, Radio, RadioGroup } from "@chakra-ui/core";
import { useState, useEffect } from "react";
import { FormControl, FormLabel, Select } from "@chakra-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { UPDATE_PERSON_DATA, GET_ALL_IMAGES } from "../api/home";
import "./index.css";
import ModelOutput from "../OutputCorrector";
import { PREDICTION, PREDICTION_EXT, TABLE, PREDICTION_FINAL, NEW_PREDICTION_FINAL, PREDICTION_OPTION, PREDICTION_DRESS, CLOTH_DRESS } from "../main";
import CoatW from '../../images/longcoatW.jpg';
import coatM from  '../../images/coat.jpg'
import dressW from '../../images/dressW.jpg'
import jacketW from '../../images/jacketW.webp';
import jacket from '../../images/jacket.webp'
import black from '../../images/black.png'

function Home(props) {
  const { person_data } = props;

  const [showDropdown, setShowDropDown] = useState(false);
  const [edit, setEdit] = useState(true);
  const [picData, setPicData] = useState();
  const [extraPicData, setExtraPicData] = useState();
  const [toggleImage, setToggleImage] = useState(false);
  const [loadImage, setLoadImage] = useState('prediction')
  // const onInputChange = (e) => {
  //   setFormFields({ ...formFields, [e.target.name]: e.target.value });
  // };

  useEffect(()=>{
    setLoadImage(person_data['prediction_type'])
  },[person_data])
  const linkChange = (link) => {
    console.log(person_data)
    // if(person_data && person_data.cloth_id){
    // link = `https://storage.cloud.google.com/naman-bucket/dataset/newpersons/${person_data.cloth_id}.jpg`;
    // }
    // if(person_data && person_data.id){
    //   link = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/tops/cloth-images_800x600/${person_data.cloth_id}.png`;
    // }

    // if(person_data && person_data.id>5000){
    //   link = `https://storage.cloud.google.com/labelling-tools-data/new_cloth_images/all_cloth_images/${person_data.cloth_id}.png`
    // }

    // if(person_data && person_data['id']>=1000000){
    //   link = `${CLOTH_DRESS}${person_data.cloth_id}${PREDICTION_EXT}`
    // }
    // if(person_data && person_data.id <= 13185){
      // link = person_data.map_cloth.image_url
    // }

    
    // if(person_data && person_data.id > 13185){
      // link  = `https://storage.cloud.google.com/labelling-tools-data/new_cloth_images/all_cloth_images/${person_data.cloth_id}.png`;
    // }

    if(person_data && person_data.id>1){
      link = `https://storage.cloud.google.com/labelling-tools-data/person_images_combined_white_padding/${person_data.person_id}.png`;
    }

    if (link === undefined) {
      return undefined;
    } else {
      return `https://storage.googleapis.com/download/storage/v1/b/${
        link.split("/")[3]
      }/o/${link
        .substring(34 + link.split("/")[3].length)
        .replace(/[/]/g, "%2F")}?alt=media`;
    }
  };

  const linkChange_id = (id) => {
    // let link = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/tops/worst_case_cloth_mask_800x600/${id}.png`;
    // // if(person_data){
    // //   link  = `${PREDICTION}${id}${PREDICTION_EXT}`
    // // }
    // // if(person_data && person_data['id']>=1000){
    // //   link = `${PREDICTION_FINAL}${id}${PREDICTION_EXT}`
    // // }
    // if(person_data && person_data['id']>=5000){
    //   link = `https://storage.cloud.google.com/labelling-tools-data/new_cloth_images/all_cloth_masks/${person_data.cloth_id}.png`
    // }
    // if(person_data && person_data['id']>=1000000){
    //   link = `${PREDICTION_DRESS}${id}${PREDICTION_EXT}`
    // }
    let link = `https://storage.cloud.google.com/labelling-tools-data/new_cloth_images/all_cloth_masks/${id}.png`;
    if(person_data && person_data.id <= 13185){
      link  = `${PREDICTION}${id}${PREDICTION_EXT}`
    }
    
    if(person_data && person_data.id > 13185){
      link = `https://storage.cloud.google.com/labelling-tools-data/new_cloth_images/all_cloth_masks/${person_data.cloth_id}.png`;
    }    
    if(person_data && person_data.id > 33350){
      link = `https://storage.cloud.google.com/labelling-tools-data/new_person_images/pcb/${person_data.person_id}.png`;
    }
    
    // `https://storage.cloud.google.com/naman-bucket/dataset/parsings/${id}_parse_vis.png`;
    if (id === undefined) {
      return undefined;
    } else {
      return `https://storage.googleapis.com/download/storage/v1/b/${
        link.split("/")[3]
      }/o/${link
        .substring(34 + link.split("/")[3].length)
        .replace(/[/]/g, "%2F")}?alt=media`;
    }
  };

  const linkChange_id_sil = (id) => {
    let link = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/tops/worst_case_cloth_mask_800x600/${id}.png`;
    // if(person_data){
    //   link  = `${PREDICTION}${id}${PREDICTION_EXT}`
    // }
    if(person_data && person_data['id']>=5000){
      link = `https://storage.cloud.google.com/labelling-tools-data/new_cloth_images/all_cloth_masks/${person_data.cloth_id}.png`
    }
    if(person_data && person_data['id']>=1000000){
      link = `${PREDICTION_DRESS}${id}${PREDICTION_EXT}`
    }
    // `https://storage.cloud.google.com/naman-bucket/dataset/parsings/${id}_parse_vis.png`;
    if (id === undefined) {
      return undefined;
    } else {
      return `https://storage.googleapis.com/download/storage/v1/b/${
        link.split("/")[3]
      }/o/${link
        .substring(34 + link.split("/")[3].length)
        .replace(/[/]/g, "%2F")}?alt=media`;
    }
  }

  return (
    <div>
    <div style={{ display: "flex", padding: "0px 10px" }}>
      {/* <Box margin="50px 0px 0px 0px">
        <Image
          // size="500px"
          height="512px"
          width="384px"
          src={
            person_data &&
            person_data.map_person.image_url &&
            linkChange(
              person_data.map_person.image_url
            )
          }
          alt="No image in database"
          id="storage_image"
        />
      </Box> */}

      <Box>
        <div style={{ display: "flex", justifyContent: 'center' }}>
          <div style={{ padding: "5px 0px" }}>
            The image is {person_data.isLabelled}
            {PREDICTION_OPTION && person_data && <RadioGroup onChange={e => setLoadImage(e.target.value)} value={loadImage} style={{ display: 'flex', padding: '0px 5px' }}>
                <Radio value={"prediction"} style={{ margin: '0px 5px' }}>Use Prediction</Radio>
                <Radio value={"scratch"} style={{ margin: '0px 5px' }}>Start from scratch</Radio>
                {/* {person_data && person_data.person_complete_bottomwear_seg && <Radio value={"ground_truth"} style={{ margin: '0px 5px' }}>Use Label</Radio>} */}
              </RadioGroup>}
          </div>
          <div>
          </div>
        </div>
        {false && <div style={{lineHeight: '40px', textAlign: 'left', display: 'flex'}}>
          <div style={{width: '50px', backgroundColor:'rgba(52,86,128, 255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(0,0,255, 255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(121, 35, 196,255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(255, 255, 38,255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(85,85,0,255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(0, 255, 0,255)', height: '40px'}}></div>
            LEFT & RIGHT
          <div style={{width: '50px', backgroundColor:'rgba(85,51,0, 255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(0,128,0, 255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(255,0,0, 255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(170,0,118,255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(0,85,85, 255)', height: '40px'}}></div>
          <div style={{width: '50px', backgroundColor:'rgba(0,0,85,255)', height: '40px'}}></div>
        </div>}
        {true && (
          <div style={{ display: "flex" }}>
            <div>
              <ModelOutput
                image={
                  person_data &&
                  person_data.cloth_id &&
                  linkChange(person_data.person_id)
                }
                new_image={
                  loadImage=='prediction' ? person_data &&
                  person_data.person_id &&
                  linkChange_id(person_data.person_id) : 
                  // person_data.torso_segmentation
                  black
                }
                load={true}
                isOccluded={true}
                pred_decider={"No"}
                setPicData={setPicData}
                id={person_data.id}
                canvas_name={"canvas_1"}
                url={
                  person_data &&
                  person_data.person_id &&
                  linkChange(person_data.person_id)
                }
                nextImage={props.nextImage}
                cloth_id={person_data && person_data.person_id}
                setToggleImage={setToggleImage}
                toggleImage={toggleImage}
                loadImage={loadImage}
                name={props.props.match.params.name}
                params={props.props.location.search}
              />
            </div>
          </div>
        )}
      </Box>
    </div>
    <div style={{margin: '90px 10px 10px 10px'}}>
      {/* <h2>For Reference, to distinguish between coat, dress and jackets</h2>
      <div style={{display: 'flex',gridRow: '1'}} className={"examples"}>
        <div>
          <h2>Coat for women</h2>
          <img src={CoatW} />
        </div>
        <div>
          <h2>Coat for men</h2>
          <img src={coatM} />
        </div>
        <div>
          <h2>Dress for women</h2>
          <img src={dressW} />
        </div>
        <div>
          <h2>Jacket for men</h2>
          <img src={jacket} />
        </div>
        <div>
          <h2>Jacket for women</h2>
          <img src={jacketW} />
        </div>
      </div>
    </div> */}
    </div>
    </div>
  );
}

export default Home;
