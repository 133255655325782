import gql from "graphql-tag";

export const GET_IMAGE_URL = gql`
  query MyQuery($id: Int!) {
    person_complete_bottomwear_seg(where: {id: {_eq: $id}}) {
      isLabelled
        id
        person_complete_bottomwear_seg
        background_segmentation
        # map_cloth{
        #     image_url
        # }
        # cloth_id
        person_id
        is_pred_good
        prediction_type
        # is_na
        # reason_for_na
    }
  }
`;


export const UPDATE_PERSON = gql`
  mutation MyMutation(
    $id: Int!,
    $person_complete_bottomwear_seg: String
    $background_segmentation: String
    $prediction_type: String
    $name: String
    $seconds_spent: Int
    $last_updated: timestamptz
    $worst_case_labelled: String
  ) {
    update_person_complete_bottomwear_seg(
      where: { id: { _eq: $id } }
      _set: { 
        person_complete_bottomwear_seg: $person_complete_bottomwear_seg,
          background_segmentation: $background_segmentation,
          isLabelled: "Labelled",
          is_na: "No",
          reason_for_na: "No",
          prediction_type: $prediction_type,
          name: $name,
          seconds_spent: $seconds_spent
          last_updated: $last_updated
          worst_case_labelled: $worst_case_labelled
          is_pred_good: "No"
        }
    ) {
      affected_rows
    }
  }
`;
export const allParts = [{
  variable : "person_complete_bottomwear_seg",
  color: "#00ffff",
  name: "BOTTOMWEAR SEG"
},
{
  variable: "background_segmentation",
  color: "#cccccc",
  name: "BACKGROUND"
}
]

// export const allParts = [
//   {
//     variable : "torso_seg",
//     color: "rgba(0,255,255,255)",
//     name: "TORSO",
//     main_class: {width: "160px"}
//   },
  
//   {
//     variable: "background_seg",
//     color: "#cccccc",
//     name: "BACKGROUND",
//     main_class: {width: "200px"}
//   },
//   {
//     variable : "lua_seg",
//     color: "rgba(255,255,0, 255)",
//     name: "LEFT UPPER ARM"
//   },
//     {
//     variable: "rua_seg",
//     color: "rgba(0,255,0, 255)",
//     name: "RIGHT UPPER ARM"
//   },
//   {
//     variable: "lla_seg",
//     color: "rgba(255,0,0, 255)",
//     name: "LEFT LOWER ARM"
//   },

//   {
//     variable: "rla_seg",
//     color: "rgba(0,0,255, 255)",
//     name: "RIGHT LOWER ARM"
//   },

//   {
//     variable: "straps_seg",
//     color: "rgba(255,85,255, 255)",
//     name: "STRAPS"
//   },

//   {
//     variable: "collar_seg",
//     color: "rgba(55,55,55, 255)",
//     name: "COLLAR",
//     classes: {color: "white"}
//   },
// ]


export const PRED_GOOD_AVAILABLE = true

export const TABLE = "person_complete_bottomwear_seg"

export const OUTPUT_BACKGROUND = 'https://storage.googleapis.com/naman-bucket/dataset/newpersons/'

export const OUTPUT_BACKGROUND_EXT = '.jpg'

export const PREDICTION = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/uplara_bottoms_cloths/bottoms_seg_256x192/`

export const PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/stitching/`

export const NEW_PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/stitching/`

export const PREDICTION_DRESS =  `https://storage.cloud.google.com/labelling-tools-data/data_dumps/dresses/worst_case_cloth_mask_800x600/`

export const PREDICTION_EXT = `.png`

export const CLOTH_DRESS = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/dresses/cloth-images_800x600/`

export const UPDATE_PRED_GOOD = gql`mutation MyMutation($id: Int, $is_pred_good: String, $isLabelled: String) {
  update_person_complete_bottomwear_seg(where: {id: {_eq: $id}}, _set: {is_pred_good: $is_pred_good, isLabelled: $isLabelled}) {
    affected_rows
  }
}`;

export const RELATED = false

export const GET_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  person_complete_bottomwear_seg_aggregate(distinct_on: id, where: {isLabelled: {_eq: "Not Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
    }
  }
}
`;

export const WORST_CASE_QUERY = gql`
query  MyQuery($id: Int!, $worst_case_date: String) {
  person_complete_bottomwear_seg(order_by: {id: asc}, where: {worst_case_date: {_eq: $worst_case_date}, id: {_gte: $id}}, limit: 2) {
    id
  }
}
`;

export const UPDATE_PERSON_NA = gql`
  mutation MyMutation(
    $id: Int!,
    $is_na: String,
    $reason_for_na: String!,
    $isLabelled: String!,
    $last_updated: timestamptz,
    $worst_case_labelled: String
  ){
      update_person_complete_bottomwear_seg(
        where: { id: { _eq: $id } }
        _set: {
          isLabelled: $isLabelled,
          is_na: $is_na,
          reason_for_na: $reason_for_na
          last_updated: $last_updated
          worst_case_labelled: $worst_case_labelled
        }
      ) {
        affected_rows
        returning {
          is_na
        }
      }
    }
`;

export const IS_PERSON = false

export const IGNORE_OPTION =  false

export const PREDICTION_OPTION = true

export const LINE_WIDTH = 1

export const POINT_WIDTH = 1